import { useEffect, useState } from 'react';

import { LogEvent } from '@analytics';
import { Banner, Carousel, CarouselApi, CarouselContent, CarouselItem } from 'design-system';
import Link from 'next/link';

interface HomeBannerContent {
  src: string;
  path: string;
  alt: string;
  title: string;
  body: string;
}

const HomeBannerContents: HomeBannerContent[] = [
  {
    src: '/banners/guide_banner.png',
    path: '/why-drivingteacher',
    alt: '면허 가장 효율적으로 따는 법',
    title: '운전학원 방문 예약, \n 왜 불편할까?',
    body: '면허 가장 효율적으로 따는 법',
  },
  {
    src: '/banners/simulation.png',
    path: '/why-simulation',
    alt: '실내운전연습장은 이런 곳이에요',
    title: '실내운전연습장은 이런 곳이에요!',
    body: '실내운전연습장에 대해 알아보기',
  },
  {
    src: '/banners/community.png',
    path: 'https://open.kakao.com/o/gS2E4tVf',
    alt: '무물보 커뮤니티',
    title: '원하는 정보가 없다면? 무물보!',
    body: '운전선생 공식 커뮤니티 참여하기',
  },
];

interface HomeBannerProps {
  backgroundColor: 'white' | 'gray';
  indicator: 'dot' | 'number' | 'none';
}

export const HomeBanner = ({ backgroundColor, indicator }: HomeBannerProps) => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState<number>(0);

  useEffect(() => {
    if (!api) return;

    api.scrollTo(current);
  }, [current]);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCurrent(api.selectedScrollSnap());

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap());
    });
  }, [api]);

  return (
    <>
      <Carousel
        setApi={setApi}
        plugin={{ autoPlay: true }}
        opts={{ loop: true }}
        className=" -mx-16"
      >
        <CarouselContent>
          {HomeBannerContents.map((content, index: number) => {
            const { src, path, alt, title, body } = content;
            return (
              <CarouselItem key={'home-banner-' + index} className="mr-0 w-full px-16">
                <Link
                  href={path}
                  onClick={() => {
                    LogEvent.활성화.homeCTA(`작은 배너 - ${alt}`);
                  }}
                >
                  <Banner
                    size="medium"
                    type="title+description"
                    description={body}
                    title={title}
                    image={src}
                    alt={alt}
                    backgroundColor={backgroundColor}
                  ></Banner>
                </Link>
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>
    </>
  );
};
